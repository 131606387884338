@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #efefef;
  background: #0c3020;
}

a {
  color: #ffffff;
  text-decoration: none;
}



/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #327947;
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #387c54;
}




.head {
  max-width: 46%;
  margin: 0 auto;
}

.h2tag {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #7ecf3b;
}

.h3tag {
  font-size: 30px;
  font-weight: 500;
}

.h5tag {
  font-size: 20px;
  font-weight: 700;
}

.para {
  font-size: 16px;
  color: #efefef;
}

.primary_btn {
  background: linear-gradient(253deg, #ff8080, transparent) , url("./assets/images/btn_img.png") no-repeat left;
  border: 1px solid #ff8d8d;
  font-size: 20px;
  font-weight: 600;
  border-radius: 30px;
  color: #efefef;
  padding: 6px 25px;
  position: relative;
  background-size: 200px;
  overflow: hidden;
}
.primary_btn .round {
  border-radius: 50%;
  background-color: #ff9898;
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: -1;
  animation: scale-down 0.2s forwards;
}

.primary_btn.animate .round {
  animation: scale-up 0.5s forwards;
}

@keyframes scale-up {
  to {
      transform: scale(600);
  }
}

@keyframes scale-down {
  from {
      transform: scale(600);
  }
  /* to {
      ransform: scale(0);
  } */
}

/* 
.primary_btn:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 60px;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  filter: blur(30px);
  transform: translateX(-100px) skewX(-15deg);
}

.primary_btn:after {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 100%;
  left: 30px;
  top: 0;
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100px) skewX(-15deg);
}

.primary_btn:hover:before {
  transform: translateX(300px) skewX(-15deg);
  opacity: 0.6;
  transition: 0.7s;
}

.primary_btn:hover:after {
  transform: translateX(300px) skewX(-15deg);
  opacity: 1;
  transition: 0.7s;
} */



.btn_bdr {
  border: 1px solid #432129;
  background: transparent;
  font-size: 18px;
  color: #24020a;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 30px;
}

section {
  padding: 40px 0;
  overflow-x: hidden;
}







.navbar-sticky--moved-up {


  margin-top: -100px;
  
  background: #0c3020 !important;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.main_navbar {
  background: transparent;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
} 

.main_navbar .navbar-brand {
  color: #24020a;
  font-size: 22px;
  font-weight: 600;
  font-family: 'ClashDisplay';
  text-decoration: none !important;
}

.main_navbar ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.main_navbar ul li {
  margin-left: 10px;
}

.main_navbar ul li:nth-last-child(1) {
  margin-left: 10px;
}

.main_navbar ul li a.active {
  color: #efefef !important;
  border: 1px solid #ff8d8d;
  border-radius: 30px;
}
.main_navbar ul li:hover a{
  color: #efefef !important;
  border: 1px solid #ff8d8d;
  border-radius: 30px;
}

.main_navbar ul li a {
  color: #d3de87 !important;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid transparent;
  cursor: pointer;
}

.main_navbar .navbar-toggler {
  background-color: #6f742d;
  color: #619338;
  box-shadow: none;
  border: 0;
}

.main_navbar .offcanvas.offcanvas-end {
  background: #0c3020;
  transition: .8s;
}


/* Connect Wallet */
.primary_modal .modal-content {
  background: #0f331a;
  border: 1px solid #387c54;
  border-radius: 20px;
}

.primary_modal .modal-header {
  border-bottom: 0px solid #ffafbf;
  padding: 0;
}
.primary_modal .modal-body {
  padding: 50px 80px;
}
.primary_modal .modal-body h2 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
}
.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #000;
}
.btn-close {
  width: 31px;
  height: 31px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close_icon.png") no-repeat;
  opacity: 1;
  outline: none;
  box-shadow: none !important;  
  border-radius: 0;
  top: 17px;
  position: absolute;
  right: 0px;
  z-index: 10;
}

.btn-close:hover {
  opacity: 1;
}
.buy_token_modal_wrap {
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
}
.buy_token_modal_single {
  border: 1px solid #0e2013;
  border-radius: 20px;
  background: #1d562e;
  transition: 0.2s all;
  text-align: center;
  padding: 50px 30px;
  width: 50%;
  display: block;
}
.buy_token_modal_single h3 {
  font-size: 22px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  -webkit-text-stroke: unset;
  margin: 30px 0 0;
  text-transform: unset;
  line-height: 36px;
  transition: 0.2s all;
}
.buy_token_modal_single:hover {
  background: #3d935d;
}
.buy_token_modal_single:hover h3 {
  color: #ffffff;
}
.primary_modal a {
  text-decoration: none;
}

.bold {
  font-size: 22px;
  font-weight: 700;
}
.page_header {
  background: url("./assets/images/banbg.png") no-repeat center;
  background-position: 50% 38%;
  position: relative;
}
.page_header::before {
  content: "";
  position: absolute;
  bottom: -50px;
  background: url("./assets/images/banborder.png") no-repeat center;
  background-position: bottom;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background-size: 100%;
  z-index: -1;
}

.page_header::after {
  content: "";
  position: absolute;
  background: url("./assets/images/bandots.png") no-repeat center;
  width: 655px;
  height: 600px;
  left: 0;
  top: 0;
  z-index: -1;
}
.banner{
  position: relative;
  padding-top: 190px;
}
.banner::before{
  content: "";
  position: absolute;
  background: url("./assets/images/banmoon.png") no-repeat right;
  right: 0;
  top: 60px;
  width: 463px;
  height: 413px;
}

.page_header h1 {
  color: #fff;
  font-size: 36px !important;
  margin-bottom: 45px;
  font-weight: 400;
}
.page_header h1 span{
  color: #7ecf3b;
  font-weight: 700;
}
.page_header .h2box{
  background: #0c3020;
  border: 1px solid #1f5d31;
  border-radius: 25px;
  padding: 25px;
}
.page_header .h2box h2{
  font-weight: 400;
  margin-bottom: 0;
  color: #efefef;
}
.page_header .h2box h2 span{
  font-weight: 700;
}

.page_header .head p {
  font-size: 24px;
}

.page_header .addr {
  font-size: 14px;
  color: #fff;
}
.page_header .addr img{
  cursor: pointer;
}
.page_header .addr img:hover{
  filter: invert(0) brightness(1.7);
}
.page_header ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
}

.page_header ul li {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_header ul li+li {
  margin-left: 20px;
}
.page_header ul li:hover {
  border: 1px solid #ff807c;
}
.page_header ul li:hover img {
  filter: invert(75%) sepia(32%) saturate(4171%) hue-rotate(342deg) brightness(302%) contrast(100%);
}
.page_header .exchanges{
  text-align: center;
}
.page_header .exchanges h2{
  margin-bottom: 15px;
}
.page_header .box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9a9388;
  border-radius: 15px;
  padding: 10px 12px;
  font-size: 24px;
  margin: 10px 0;
  color: #fff !important;
}
.page_header .box:hover {
  border: 1px solid #ff8080;
}
.page_header .box p {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 16px;
}
.story_sec {
  padding-top: 90px;
  background: url("./assets/images/border_img.png") no-repeat bottom;
  background-size: 100%;
  overflow: hidden;
  position: relative;
}
.story_sec::before {
  content: "";
  position: absolute;
  background: url("./assets/images/about_shade.png") no-repeat bottom;
  width: 1016px;
  height: 127px;
  right: 0;
  bottom: 90px;
  background-size: contain;
  margin: auto;
  left: 0;
}

.story_sec::after {
  content: "";
  position: absolute;
  background: url("./assets/images/about_stars.png") no-repeat bottom;
  width: 655px;
  height: 600px;
  right: 0;
  top: 100px;
  background-size: contain;
  margin: auto;
  left: 0;
}

.story_sec .imgbox{
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.how_sec {
  background: url("./assets/images/how_bdr.png") no-repeat bottom;
  background-size: 100%;
}

.how_sec .box {
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 20px 50px;
  border: 2px solid #154929;
  margin: 35px 0;
}
.how_sec .box:hover{
  border: 2px solid #f17c7b;
}
.how_sec .box:hover .bold{
  color:#f17c7b ;
} 
.how_sec .box img {
  margin-right: 25px;
  width: 73px;
}

.how_sec .bold {
  margin-bottom: 5px;
  font-size: 20px;
  color: #abb345;
}



.poopmap {
  background: url("./assets/images/map_bdr.png") no-repeat bottom;
  background-size: 100%;
  overflow: hidden;
}
.poopmap .image{
 margin-top: -150px;
}
.poopmap .mob_view{
  display: none;
}
.poopmap .box{
  margin-bottom: 15px;
}
.poopmap .box ul{
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.poopmap .box p{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #adb543;
}
.economic_sec {
  background: url("./assets/images/tokenomic_bdr.png") no-repeat bottom;
  background-size: 100%;
}

.economic_sec h5 {
  font-size: 20px;
  font-weight: 600;
  color: #adb543;
  margin-bottom: 20px;
}

.economic_sec .para {
  margin-bottom: 0;
}

.economic_sec p span {
  font-size: 16px;
  color: #ff8080;
}
.economic_sec p span a {
  cursor: pointer;
}


.economic_sec h3 {
  font-size: 28px;
  font-weight: 600;
  color: #ff8080;
  margin-bottom: 35px;
}
.economic_sec .boxes{
  max-width: 85%;
  margin: 0 auto;
}
.economic_sec .boxes img{
  margin-bottom: 30px;
}
.economic_sec h3 span{
  color: #efefef;
}
.economic_sec ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 80px;
}

.economic_sec li {
  position: relative;
  margin-bottom: 20px;
}
/* 
.economic_sec li::before {
  position: absolute;
  content: "";
  background: #000;
  left: -30px;
  top: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #000;
}

.economic_sec li:nth-child(1)::before {
  background: #d36f3d;
}

.economic_sec li:nth-child(2)::before {
  background: #ffe1a8;
}

.economic_sec li:nth-child(3)::before {
  background: #d88d5b;
}
.economic_sec li:nth-child(4)::before {
  background: #b25a1f;
}
.economic_sec li:nth-child(5)::before {
  background: #e29374;
}
.economic_sec li:nth-child(6)::before {
  background: #e5ac7a;
} */

.economic_sec li span {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #adb543;
}

.economic_sec li span img {
  margin-left: 10px;
}

.economic_sec .bdr {
  background: #492b00;
  width: 100%;
  height: 2px;
  margin: 20px 0;
}






.faq_sec .accordion {
  background: transparent !important;
  overflow: hidden;
}

.faq_sec .accordion .para {
  font-size: 16px;
  margin-bottom: 5px;
}

.faq_sec .accordion-item {
  background: #0c3020 !important;
  margin-bottom: 15px;
  border: 2px solid #164d2b;
  border-radius: 15px;
  color: #efefef !important;
  font-size: 16px;
  font-weight: 400;
  padding: 0 30px;
}

.faq_sec .accordion-item.collapsed {
  background: #0c3020  !important;
}

.faq_sec .accordion-item.collapsed .accordion-button {
  color: #efefef !important;
}

.faq_sec .accordion-item[aria-expanded="true"] {
  background: #0c3020;
}

.faq_sec .accordion-body {
  padding-top: 0;
}

.faq_sec .accordion-button::after {
  margin-top: -4px;
}

.faq_sec .accordion-button:not(.collapsed) {
  color: #efefef !important;
}

.faq_sec .accordion-item:not(.collapsed) .accordion-button::after {
  /* background: url("./assets/images/minus.png") no-repeat center; */
  height: 9px;
  width: 9px;
  border: 6px solid #ff000000;
  border-bottom-color: #ff8080;
}

.faq_sec .accordion-item.collapsed .accordion-button::after {
  /* background: url("./assets/images/plus.png") no-repeat center; */
  height: 9px;
  width: 9px;
  border: 6px solid #ff000000;
  border-right-color: #ff8080;
}

.faq_sec .accordion-button {
  background: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
  color: #fff;
  font-size: 16px;
}

.footer {
  padding-top: 30px;
  background: #1c4027;
}
.footer p.bold{
  font-size: 14px;
  color: #adb543;
  font-weight: 600;
  margin-bottom: 15px;
}
.footer p{
  font-size: 14px;
  margin-bottom: 5px;
  color: #efefef;
}
.footer ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 35px;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.footer ul li {
  border: 1px solid #1f6634;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  background: #1f6634;
}

.footer ul li:hover {
  border: 1px solid #fb7f7f;
  background: transparent;
}

/* .footer ul li:hover img {
  filter: invert(29%) sepia(55%) saturate(6777%) hue-rotate(6deg) brightness(98%) contrast(94%);
} */

.footer .footer_panel_blw {
  font-size: 14px;
  color: #997542 !important;
  /* border-top: 1px solid #333333; */
  padding-top: 10px;
  margin-top: 15px;
  text-align: center;
}
.footer .footer_panel_blw p{
  color: #adb543;
}
.footer .footer_panel_blw a {
  color: #333333 !important;
  text-decoration: none;
}


/* star  animation */
.star_img {
  position: relative;
}

.blinking-star {
  -webkit-animation: blink-animation 1s infinite;
  animation: blink-animation 1s infinite;
  background: url("./assets/images/stars.png") no-repeat center;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

@-webkit-keyframes blink-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes blink-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.blinking-star-about {
  background: url("./assets/images/about_stars.png") no-repeat center;
}

.blinking-star-why {
  background: url("./assets/images/why_stars.png") no-repeat center;
  background-position: 50% 25px;
}

.blinking-star-roadmap {
  background: url("./assets/images/roadmap_stars.png") no-repeat center;
  background-position: 0 0;
}

.blinking-star-faq {
  background: url("./assets/images/why_stars.png") no-repeat center;
  background-position: top;
  background-size: contain;
}
.rewards_highlight {
  color: #e58007;
  -webkit-text-stroke: unset;
  font-size: 28px;
  margin: 20px 0 30px;
  border: 1px solid #e58007;
  display: inline-block;
  border-radius: 100px;
  padding: 12px 40px;
}
.chain_details_div {
  text-align: left;
  margin-top: 50px;
}
.chain_details_div h3 {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  -webkit-text-stroke: unset;
  text-shadow: 0 0 6px #000;
}
.chain_details_div .primary_btn {
  margin: 10px 0 40px;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  color: #7ecf3b;
  background: #0c3020;
  border: 1px solid #e2e4e3;
  border-radius: 12px;
  padding: 8px;
}
.chain_details_div .primary_btn:hover::after,
.chain_details_div .primary_btn:hover::before {
  display: none;
}



/* media queries  */
@media (max-width:1450px) {
  .banner::before{
   width: 390px;
   height: 290px;
   background-size: contain;
  }
}
@media (max-width:1399px) {
  .main_navbar ul {
    flex-direction: column;
  }

  .main_navbar ul li {
    margin: 10px 0;
  }

  .page_header .box {
    font-size: 22px;
  }
}

@media (max-width:1199px) {
  .banner .ban{
   flex-direction: column-reverse;
  }
  .page_header h1{
    font-size: 34px;
  }
  .page_header::after{
    width: 50%;
  }
  .banner::before{
    z-index: -1;
  }
  .banner .col-xl-7{
    text-align: center;
  }
  .head {
    max-width: 56%;
  }
  .chain_details_div {
    margin-bottom: 30px;
  }
}

@media (max-width:991px) {
  .head {
    max-width: 100%;
  }


  .page_header h1 {
    font-size: 42px;
  }

  .page_header ul li {
    width: 52px;
    height: 52px;
    margin: 0 15px;
  }

  .page_header .box {
    font-size: 20px;
  }
  .banner::before {
    height: 230px;
  }
  .primary_modal .modal-dialog {
    max-width: 95%;
    margin: 15px auto;
  }
  .poopmap .image {
    margin-top: -90px;
}
}

@media (max-width:767px) {
  .faq_sec img {
    margin-top: 30px;
  }
  section {
    padding: 30px 0;
    text-align: center;
  }

  .story_sec {
    padding: 55px 0;
  }

  .page_header ul {
    flex-wrap: wrap;
  }

  .page_header ul li {
    margin: 8px 15px;
  }
  .banner .banimg{
    max-width: 56%;
    margin-top: 20px;
  }
  .how_sec .bold {
    margin-bottom: 10px;
  }
  .how_sec .box{
    padding: 20px;
  }
  .how_sec .box{
    border-radius: 40px;
  }
  .how_sec .box {
    flex-direction: column;
  }
  .how_sec .box img{
    margin: 0 0 10px;
    width: unset;
  }
  .economic_sec .text-center img{
    margin-bottom: 15px;
  }
  .economic_sec li span {
    justify-content: center;
  }
  
  .economic_sec ul{
    max-width: fit-content;
    margin: 0 auto;
    text-align: center;
    margin-top: 14px;
  }
  .footer {
    padding-top: 40px;
    text-align: center;
  }
  .footer .head{
    max-width: 100%;
  }
  .footer ul{
    justify-content:center;
  }
  .faq_sec {
    overflow: hidden;
  }
  .faq_sec .accordion-item{
    border-radius: 40px;
  }
  .buy_token_modal_wrap {
    flex-wrap: wrap;
  }
  .buy_token_modal_single {
    width: 100%;
  }
  .poopmap .map_img{
    display: none;
  }
  .poopmap .mob_view{
    display: block;
  }
  .poopmap .image{
    margin-top: 15px;
  }
}

@media (max-width:575px) {
  .page_header h1{
    font-size: 30px !important;
  }
  .banner::before{
    height: 180px;
  }
  .banner .banimg {
    max-width: 66%;
    margin-left: -80px;
}
  .h2tag {
    font-size: 27px;
  }
  .page_header .ban_img {
    margin-right: 0px;
  }


  .page_header h1 {
    font-size: 36px;
  }


  .bold{
    font-size: 19px;
  }
  .page_header ul {
    justify-content: flex-start;
  }
  .primary_modal .modal-body {
    padding: 50px 20px;
  }
  .buy_token_modal_single {
    padding: 30px 15px;
  }
  .buy_token_modal_single h3 {
    font-size: 20px;
    line-height: 28px;
  }
}